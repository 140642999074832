const Logo = () => (
  <div className="brand">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.69 189">
      <path d="M296,212.41A49.46,49.46,0,0,0,214.19,197l2,1.88a46.72,46.72,0,0,1,77.17,14.41,3.42,3.42,0,0,0,2.34,5.93,3.42,3.42,0,0,0,.25-6.84Z" transform="translate(-174.53 -155.43)" />
      <path d="M186.19,265.92a3.36,3.36,0,0,0,.71-2.07,3.44,3.44,0,1,0-3.44,3.44,3,3,0,0,0,.31,0,75.69,75.69,0,0,0,52.91,38.17l.49-2.7A72.93,72.93,0,0,1,186.19,265.92Z" transform="translate(-174.53 -155.43)" />
      <path d="M199.38,173.3a3.43,3.43,0,0,0-3.17,4.76,75.68,75.68,0,0,0-18.38,75.13l2.64-.81A72.88,72.88,0,0,1,198.2,180a3.3,3.3,0,0,0,1.18.22,3.44,3.44,0,0,0,0-6.87Z" transform="translate(-174.53 -155.43)" />
      <path d="M321.07,204.85a75.85,75.85,0,0,0-35.2-40.45l-1.3,2.43a73,73,0,0,1,33.89,38.91,3.44,3.44,0,1,0,2.61-.89Z" transform="translate(-174.53 -155.43)" />
      <path d="M203.39,231a46.59,46.59,0,0,1,3.06-16.63,2.45,2.45,0,0,0,.38,0,3.44,3.44,0,1,0-3.44-3.43,3.38,3.38,0,0,0,.66,2,49.44,49.44,0,0,0,37.32,66.71c.16-.9.33-1.8.49-2.7A46.75,46.75,0,0,1,203.39,231Z" transform="translate(-174.53 -155.43)" />
      <path d="M273.71,157a3.43,3.43,0,0,0-2.73,1.36,75.63,75.63,0,0,0-62.84,9.8l1.53,2.28A72.93,72.93,0,0,1,270.33,161a3.43,3.43,0,1,0,3.38-4Z" transform="translate(-174.53 -155.43)" />
      <path d="M237.26,287a3.44,3.44,0,0,0-2.93,1.66,59.86,59.86,0,0,1-43.19-47.91l-2.71.44A62.61,62.61,0,0,0,234,291.46a3.43,3.43,0,1,0,3.27-4.42Z" transform="translate(-174.53 -155.43)" />
      <path d="M235.53,173l-.68-2.67a62.57,62.57,0,0,0-47.1,56.37,3.43,3.43,0,1,0,2.74.31A59.8,59.8,0,0,1,235.53,173Z" transform="translate(-174.53 -155.43)" />
      <path d="M250.47,168.5a3.44,3.44,0,1,0,0,2.75,59.8,59.8,0,0,1,57.22,43.53l2.64-.78A62.56,62.56,0,0,0,250.47,168.5Z" transform="translate(-174.53 -155.43)" />
      <circle cx="88.58" cy="60.96" r="13.1" />
      <path d="M276,328.9c-6.9,10-13.29,15.15-21.07,15.51-13.51.62-19.29-11.88-13.84-30.69,4.16-14.35,9-28.23,13.56-42.35,1.78-5.58,4.76-11.09,1.28-17.5-3.67-6.76-8.57-5.33-13.35-4.18-1.29.31-2.53,1.15-4,.87,8.61-12.39,18.24-21.12,30.8-17.62,8,2.24,12.62,17.33,9.24,31.61-3.09,13-7.22,25.48-10.65,38.34a126.9,126.9,0,0,0-3.17,16.43c-1.39,10.8.54,13.26,7.61,10.41A31.94,31.94,0,0,1,276,328.9Z" transform="translate(-174.53 -155.43)" />
    </svg>
  </div>
);

export default Logo;
